import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

export default function Vereniging() {
  return (
    <Layout>
      <Seo title="Vereniging" />
      <div className="container py-8">
        <h1 className="h1">Vereniging</h1>
        <p className="paragraph">
          Laat een professionele website voor uw vereniging op maat ontwerpen
          door Thomas' Webdesign.
        </p>
      </div>
    </Layout>
  )
}
